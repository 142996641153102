import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchCrudTableDataById, fetchSidebarData, fetchtabledata, fetchTableData } from "../../customhook/FetchApi";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import axios from "axios";
import qs from "qs";
import Swal from "sweetalert2";

import ReactPaginate from "react-paginate";
import { CrudTableData } from "../ContextProviderCrudTableData";
import { exportData } from "../../utitls/export/export";
import useStore from '../../zustand/ReactStore'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const SERVER_URL = 'https://admissioninnepal.in/'

function DisplayCrudTableData() {

  // List of known image extensions
  const imageExtensions = ['gif', 'png', 'tiff', 'bmp', 'jpeg', 'jpg'];
  // List of known file extensions (PDF, DOCX, etc.)
  const fileExtensionList = ['pdf', 'xlsx', 'doc', 'docx', 'txt']; // Add more extensions as needed

  const [crudTable, setCrudTable] = useState([])

  const [TableData, setTableData] = useState([]);

  const [selectedRole, setSelectedRole] = useState("");
  const [selectedFormat, setSelectedFormat] = useState("");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5; // Number of items per page
  const [searchTerm, setSearchTerm] = useState("");

  const [visibleFieldColumn, setVisibleFieldColumn] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [crudTableId, setCrudTableId] = useState("")
  const { table_name } = useParams()

  const [displayAllFieldStatus, setDisplayAllFieldStatus] = useState(false)
  const [displayVisibileFieldStatus, setDisplayVisibileFieldStatus] = useState(true)



  const crudDataFromZustandStore = useStore((state) => state.crudDataFromZustandStore)

  var exactTableName = table_name.startsWith("tbl_") ? table_name : "tbl_" + `${table_name}`


  // update sidebar data, crud-edit done and in new tab press chrome back arrow 
  const updateSidebarDatafromZustand = useStore((state) => state.updateSidebarDatafromZustand);


  // fetch crud_id from zustand store
  useEffect(() => {
    if (crudDataFromZustandStore.length === 0) {
      setError("No data found in store.");
      setLoading(false);
      return;
    }


    const tableData = crudDataFromZustandStore.find((data) => data.table_name === exactTableName);



    if (tableData) {
      setCrudTableId(tableData.id);

      setCrudTable(tableData)
    } else {
      setError("Table name or ID not found.");
    }

    setLoading(false);
  }, [crudDataFromZustandStore, exactTableName]);



  useEffect(() => {
    if (!loading && (error || !crudTableId)) {
      navigate("/dashboardpage");
    }
  }, [error, crudTableId, navigate]);


  const [allFieldColumns, setAllFieldColumns] = useState([])

  const getTableData = async () => {
    let tableColumns = []

    // if emtpy or undefined return
    if (!crudTable || Array.isArray(crudTable) && crudTable.length == 0) return

    // check crudTable is empty or undefined
    const keyvalue = "12321"; // Define the key value or fetch it as needed
    const tableName = crudTable.table_name.replace("tbl_", "");

    try {
      const table = await fetchtabledata(tableName);
      console.log("table")
      console.log(table)

      // Extract visiblefields and store in visibleFieldColumn
      const visibleFieldColumn = table.visiblefields;
      delete table.visiblefields;
      setVisibleFieldColumn(visibleFieldColumn);


      // Validate if table is an array (excluding visiblefields)
      const tableArray = Object.values(table).filter(

        (item) => typeof item === "object"

      );


      if (Array.isArray(tableArray) && tableArray.length !== 0) {

        setTableData(tableArray);

        Object.entries(tableArray[0]).forEach(([key, value]) => {
          tableColumns.push(key)
        })

        setAllFieldColumns(tableColumns)

      } else {
        setAllFieldColumns([])
      }

    } catch (error) {
      setError(error);
      console.error("Error fetching table data:", error);
    } finally {
      setLoading(false);
    }
  };



  // Use useEffect to call the async function
  useEffect(() => {
    getTableData();
  }, [crudTable]); // Include crudTable as a dependency if it might change




  // getting to-many rerendering error

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);


  };

  const filteredData = TableData.filter(
    (item) =>
      (item.region_name?.toLowerCase() || "").includes(
        searchTerm.toLowerCase()
      ) || (item.title?.toLowerCase() || "").includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0); // Reset to first page on search
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  function insertNewData() {
    // navigate("/form", {
    //   state: { crud_id: crudTable.id, table_name: crudTable.table_name },
    // });

    navigate(`/form/${crudTable.id}/${crudTable.table_name}/null`);

  }

  function editData(table_data_id) {

    navigate(`/form/${crudTable.id}/${crudTable.table_name}/${table_data_id}`);
  }



  // extract visiblefields from table and store in visibleFieldColumn and remvoe visiblefields from table.

  const deleteData = async (item) => {
    const id = item.id; // Extract just the 'id' value from the item object

    // getTableData();

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const data = qs.stringify({
            id: id, // Now passing only the ID value
            table: crudTable.table_name,
            key: 12321,
          });

          const response = await axios.post(
            "https://admissioninnepal.in/api/delete-group",
            data,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );

          if (response.status === 200) {

          

            Swal.fire({
              title: "Deleted!",
              text: "Item has been deleted.",
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => {
              window.location.reload()
            })
          }
          else {
            Swal.fire({
              title: "Error",
              text: "Failed to delete item",
              icon: "error",
              confirmButtonText: "OK",
            });
          }

          // Refresh the data

        } catch (error) {
          console.error("Error deleting item:", error);
          Swal.fire({
            title: "Error",
            text: "Failed to delete item",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };


  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (!selectedFormat) {
      Swal.fire({
        title: "Error",
        text: "Please select both role and format",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }



    // Call export function with filtered data
    exportData(filteredData, selectedRole, selectedFormat);

    Swal.fire({
      title: "Success",
      text: `Exporting data for role ${selectedRole} in ${selectedFormat} format.`,
      icon: "success",
      confirmButtonText: "OK",
    });
  };


  // when display all fields, displayVisibileFieldStatus should be false
  function displayVisibileField() {
    setDisplayAllFieldStatus(false); // Ensure "All Fields" is not displayed
    setDisplayVisibileFieldStatus(true); // Display "Visible Fields" only
  }

  // Toggle to display all fields and hide visible fields
  function displayAllFields() {
    setDisplayVisibileFieldStatus(false); // Ensure "Visible Fields" is not displayed
    setDisplayAllFieldStatus(true); // Display "All Fields" only
  }


  return (
    <>
      <Header />
      <Sidebar />

      <div className="app-main flex-column flex-row-fluid " id="kt_app_main">
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
            <div
              id="kt_app_toolbar_container"
              className="app-container container-xxl d-flex flex-stack"
            >
              <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mt-5">
                <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                  List of Data Inside {crudTable.title}
                </h1>


              </div>
            </div>
          </div>

          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-xxl"
            >
              <div className="card">
                <div className="card-header border-0 pt-6">


                  <div className="card-title">
                    {/*begin::Search*/}

                    <div className="d-flex align-items-center position-relative my-1">
                      <FontAwesomeIcon icon={faMagnifyingGlass} style={{ color: "#adbad2", }} className="ki-duotone ki-magnifier fs-3 position-absolute ms-5 " />

                      <input
                        type="text"
                        data-kt-user-table-filter="search"
                        className="form-control form-control-solid w-250px ps-13"
                        placeholder="Search here.."
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </div>

                  </div>

                  {crudTable && (
                    <div className="card-toolbar">
                      <div className="d-flex justify-content-end gap-3">

                        {/* insert data */}
                        {crudTable.add_field == 1 && (
                          <button
                            onClick={insertNewData}
                            className="btn btn-primary"
                          >
                            Insert Data
                          </button>
                        )}


                        {/* display all fields of table */}
                        <button
                          onClick={() => displayVisibileField()}
                          className="btn btn-success"

                        >
                          Dispaly Selected Fields
                        </button>

                        {/* display selected fields that is visible fields*/}
                        <button
                          onClick={() => displayAllFields()}
                          className="btn btn-secondary"
                        >
                          Display All Fields
                        </button>


                        <button
                          type="button"
                          className="btn btn-light-primary me-3"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_export_users"
                        >
                          <i className="ki-duotone ki-exit-up fs-2">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                          Export
                        </button>

                        {/*end::Add user*/}
                      </div>

                      <div
                        className="d-flex justify-content-end align-items-center d-none"
                        data-kt-user-table-toolbar="selected"
                      >
                        <div className="fw-bold me-5">
                          <span
                            className="me-2"
                            data-kt-user-table-select="selected_count"
                          />
                          Selected
                        </div>
                        <button
                          type="button"
                          className="btn btn-danger"
                          data-kt-user-table-select="delete_selected"
                        >
                          Delete Selected
                        </button>
                      </div>

                      <div
                        className="modal fade"
                        id="kt_modal_export_users"
                        tabIndex={-1}
                        aria-hidden="true"
                      >
                        {/*begin::Modal dialog*/}
                        <div className="modal-dialog modal-dialog-centered mw-650px">
                          {/*begin::Modal content*/}
                          <div className="modal-content">
                            {/*begin::Modal header*/}
                            <div className="modal-header">
                              <h2 className="fw-bold">Export Users</h2>
                              <div className="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" data-bs-dismiss="modal">



                                <i className="ki-duotone ki-cross fs-1">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            {/*end::Modal header*/}
                            {/*begin::Modal body*/}
                            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                              {/*begin::Form*/}
                              <form
                                id="kt_modal_export_users_form"
                                className="form"
                                onSubmit={handleFormSubmit}
                              >




                                <div className="fv-row mb-10">
                                  <label className="required fs-6 fw-semibold form-label mb-2">
                                    Select Export Format:
                                  </label>
                                  <select
                                    name="format"
                                    data-control="select2"
                                    data-placeholder="Select a format"
                                    data-hide-search="true"
                                    className="form-select form-select-solid fw-bold"
                                    value={selectedFormat}
                                    onChange={(e) =>
                                      setSelectedFormat(e.target.value)
                                    }
                                  >
                                    <option value="">Select a format</option>
                                    <option value="excel">Excel</option>
                                    <option value="pdf">PDF</option>
                                    <option value="csv">CSV</option>
                                    <option value="zip">ZIP</option>
                                  </select>
                                </div>
                                {/*end::Input group*/}

                                {/*begin::Actions*/}
                                <div className="text-center">
                                  <button
                                    type="reset"
                                    className="btn btn-light me-3"
                                    data-kt-users-modal-action="cancel"
                                    data-bs-dismiss="modal"
                                  >
                                    Discard
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    data-kt-users-modal-action="submit"
                                  >
                                    <span className="indicator-label">
                                      Submit
                                    </span>
                                    <span className="indicator-progress">
                                      Please wait...
                                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                    </span>
                                  </button>
                                </div>
                                {/*end::Actions*/}
                              </form>
                              {/*end::Form*/}
                            </div>
                            {/*end::Modal body*/}
                          </div>
                          {/*end::Modal content*/}
                        </div>
                        {/*end::Modal dialog*/}
                      </div>

                      <div
                        className="modal fade"
                        id="kt_modal_add_user"
                        tabIndex={-1}
                        aria-hidden="true"
                      ></div>

                      <div
                        className="modal fade"
                        id="kt_modal_add_user"
                        tabIndex={-1}
                        aria-hidden="true"
                      ></div>
                    </div>

                  )}
                </div>


                {/* <div className="table-responsive"> */}
                <div className="card-body py-4 table-responsive border border-none">

                  <table
                    className="table align-middle table-row-dashed fs-6 gy-5 overflow-auto"
                    id="kt_table_users"
                  >


                    {/* display only visible fields */}
                    {displayVisibileFieldStatus && (
                      <>

                        <thead>


                          <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                            {/* {visibleFieldColumn.map((field) => (
                              crudTable.filter( (data)=> data.i)


                            ))
                              
                            } */}

                            {visibleFieldColumn.map((field) => (
                              <th key={field}>
                                {field.replace('_', " ")}
                              </th>
                            ))}

                            <th>Action</th>

                          </tr>
                        </thead>

                
                        <tbody className="text-gray-600 fw-semibold">
                          {/* Check if there are any items to display */}
                          {currentItems.length > 0 && crudTable && TableData ? (
                            currentItems.map((data, index) => (
                              <tr key={index}>
                                {visibleFieldColumn.map((field) => {


                                  // Check if there are multiple files, split by comma
                                  const multipleFileCheck = data[field]?.split(',') || [];

                                  return (
                                    <td key={field}>
                                      {multipleFileCheck.map((file, idx) => {
                                        // Split the file path to get the extension, if any
                                        const fileChecking = file?.split('.') || [];
                                        const fileExtension = fileChecking.length > 1 ? fileChecking[fileChecking.length - 1].toLowerCase() : '';

                                        // Conditional rendering based on file extension
                                        if (imageExtensions.includes(fileExtension)) {
                                          // If it's an image
                                          return (
                                            <div key={idx}>
                                              <img src={SERVER_URL + file.trim()} alt="file" style={{ maxWidth: '100px', maxHeight: '100px', margin: '5px' }} />
                                            </div>
                                          );
                                        } else if (fileExtensionList.includes(fileExtension)) {
                                          // If it's a file like PDF, doc, etc.
                                          return (
                                            <div key={idx}>
                                              <Link to={SERVER_URL + file.trim()} target="_blank" rel="noopener noreferrer" style={{ display: 'block', margin: '5px' }}>
                                                Open File
                                              </Link>
                                            </div>
                                          );
                                        } else {
                                          // If it's neither an image nor a known file type, display the text
                                          return (
                                            <div key={idx}>
                                              <span>{file.trim()}</span>
                                            </div>
                                          );
                                        }
                                      })}
                                    </td>
                                  );
                                })}

                                {/* Action buttons section should be outside of the file mapping */}
                                <td>

                                  <div>
                                    {/* Edit button */}
                                    {crudTable.edit_field == 1 && (

                                      <button
                                        onClick={() => editData(data.id)}
                                        className="btn btn-icon btn-success"
                                      >
                                        <i className="fas fa-pencil-alt"></i>

                                      </button>
                                    )}

                                    {/* Delete button */}
                                    {crudTable.delete_field == 1 && (
                                      <button
                                        className="btn btn-icon btn-danger mx-lg-3 mt-3 mt-lg-0"
                                        onClick={() => deleteData(data)}
                                      >
                                        <i className="fas fa-trash"></i>
                                      </button>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4" className="text-center">
                                No data available
                              </td>
                            </tr>
                          )}
                        </tbody>



                      </>

                    )}


                    {/* display  all fields */}

                    {displayAllFieldStatus && (
                      <>
                        <thead>
                          <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                            {allFieldColumns.map((columnName, index) => (
                              <th key={index}>{columnName.replace('_', " ")}</th>
                            ))}

                            <th>Action</th>

                          </tr>
                        </thead>

                        <tbody className="text-gray-600 fw-semibold">
                          {currentItems.length > 0 && crudTable ? (
                            currentItems.map((data, index) => (

                              <tr key={index}>
                                {Object.entries(data).map(([key, value]) => {
                                  // Split the value by comma to check if there are multiple files
                                  const multipleFileCheck = value?.split(',') || [];


                                  console.log("multipleFileCheck")

                                  console.log(multipleFileCheck)

                                  return (
                                    <>
                                      <td key={key}>
                                        {multipleFileCheck.map((file, idx) => {
                                          // Split the file path to get the extension, if any
                                          const fileChecking = file?.split('.') || [];
                                          const fileExtension = fileChecking.length > 1 ? fileChecking[fileChecking.length - 1].toLowerCase() : '';

                                          // Conditional rendering based on file extension
                                          if (imageExtensions.includes(fileExtension)) {
                                            // If it's an image
                                            return (
                                              <div key={idx} style={{ marginBottom: '5px' }}>
                                                <img src={SERVER_URL + file.trim()} alt="file" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                              </div>
                                            );
                                          } else if (fileExtensionList.includes(fileExtension)) {
                                            // If it's a PDF or document
                                            return (
                                              <div key={idx} style={{ marginBottom: '5px' }}>
                                                <a href={SERVER_URL + file.trim()} target="_blank" rel="noopener noreferrer">
                                                  Open File
                                                </a>
                                              </div>
                                            );
                                          } else {
                                            // If it's neither an image nor a known document type, display the text
                                            return (
                                              <div key={idx} style={{ marginBottom: '5px' }}>
                                                <span>{file.trim()}</span>
                                              </div>
                                            );
                                          }
                                        })}
                                      </td>

                                    </>
                                  );



                                })}
                                <td>

                                  <div>
                                    {/* Edit button */}
                                    {crudTable.edit_field == 1 && (

                                      <button
                                        onClick={() => editData(data.id)}
                                        className="btn btn-icon btn-success"
                                      >
                                        <i className="fas fa-pencil-alt"></i>

                                      </button>
                                    )}

                                    {/* Delete button */}
                                    {crudTable.delete_field == 1 && (
                                      <button
                                        className="btn btn-icon btn-danger mx-lg-3 mt-3 mt-lg-0"
                                        onClick={() => deleteData(data)}
                                      >
                                        <i className="fas fa-trash"></i>
                                      </button>
                                    )}
                                  </div>
                                </td>

                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4" className="text-center">
                                No data available
                              </td>
                            </tr>
                          )}
                        </tbody>

                      </>
                    )}

                  </table>
                </div>

                {/* </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-stack flex-wrap mt-5 mx-5  p-5" >
          <div className="fs-6 fw-semibold text-gray-700">
            Showing {indexOfFirstItem + 1} to{" "}
            {Math.min(indexOfLastItem, filteredData.length)} of{" "}
            {filteredData.length} entries
          </div>
          <ReactPaginate
            pageCount={Math.ceil(filteredData.length / itemsPerPage)}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item previous"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item next"}
            nextLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>


    </>
  );
}

export default DisplayCrudTableData;
